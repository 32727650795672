<template>
  <ChartWrapper :title="title" :options="donutChartOptions" :series="yData">
    <template #header>
      <slot name="header"></slot>
    </template>
  </ChartWrapper>
</template>

<script>
import { mapState } from "vuex";
import { merge } from "lodash-es";
import ChartWrapper from "./components/ChartWrapper.vue";

export default {
  name: "DonutChart",

  components: { ChartWrapper },

  props: {
    title: {
      type: String,
      required: true,
    },

    xData: {
      type: Array,
      required: true,
    },

    yData: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      donutChartOptions: {
        chart: {
          type: "donut",
        },
        labels: this.xData,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: { show: true, color: "#1e293b", fontWeight: 600 },
                total: { show: true, color: "#1e293b" },
              },
            },
          },
        },
        stroke: {
          colors: ["#ffffff"],
        },
      },
    };
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        merge(this.donutChartOptions, this.options);
      },
    },

    darkTheme() {
      this.donutChartOptions.stroke.colors = this.darkTheme
        ? ["#1d2129"]
        : ["#ffffff"];
      this.donutChartOptions.plotOptions.pie.donut.labels.value.color = this
        .darkTheme
        ? "#cbd5e1"
        : "#1e293b";
      this.donutChartOptions.plotOptions.pie.donut.labels.total.color = this
        .darkTheme
        ? "#cbd5e1"
        : "#1e293b";
    },
  },
};
</script>

<style lang="scss" scoped></style>
