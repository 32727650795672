<template>
  <div class="wrapper">
    <Portal to="action-bar">
      <BaseActionButton
        v-tooltip.top="'refresh'"
        is-flat
        no-border
        icon="eva-refresh"
        class="q-ml-sm"
        @click="getForms()"
      />
      <div v-if="formList.length >= 0" class="item-list">
        <div class="row items-center">
          <div class="label">{{ selectForm }}</div>
          <BaseIcon name="eva-chevron-down" inherit-color class="icon" />
        </div>

        <q-menu fit auto-close transition-show="scale" transition-hide="scale">
          <ListItem
            v-for="item in formList"
            :key="item.id"
            :is-selected="selectedForm === item.value"
            :label="item.label"
            class="text-capitalize"
            @click="getTaskCount(item.value)"
          />
        </q-menu>
      </div>
    </Portal>
    <BaseScrollbar height="calc(100vh - 200px)">
      <div class="row">
        <div class="col-12">
          <!-- pie chart -->
          <div class="row items-start q-col-gutter-sm q-my-sm">
            <template v-for="(item, index) in taskCount.Table">
              <div :key="index" class="col-4">
                <div class="card chartBox">
                  <div class="row text-capitalize items-center header">
                    {{ item["Task Status"] }}
                  </div>
                  <div class="column">
                    <div class="col self-center q-mt-lg q-mb-sm count">
                      {{ item.totalCount }}
                    </div>
                    <div class="col self-center">
                      <BaseIcon name="mdi-triangle" color="positive" />
                      {{
                        Math.round((item.todayCount / item.totalCount) * 100) ||
                        0
                      }}
                      % increase previous day: {{ item.todayCount }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- pie chart -->
        </div>
        <div class="col-4 q-mb-sm">
          <div class="card q-mr-xs">
            <div class="row items-center">
              <div class="col q-pa-xs">
                <div class="header font-bold">
                  My Tasks ({{ openTaskList.Table?.length }})
                </div>
              </div>
              <div class="col-auto">
                <BaseActionButton
                  v-tooltip.top="'refresh'"
                  is-flat
                  no-border
                  icon="eva-refresh"
                  class="q-ml-sm"
                  @click="getOpenTaskList(formId)"
                />
                <BaseActionButton
                  v-if="showGrid1"
                  :icon="isPin ? 'mdi-pin-off' : 'mdi-pin'"
                  color="secondary"
                  no-border
                  is-flat
                  size="18px"
                  rotate="rotate-90"
                  @click="isPin = !isPin"
                />
              </div>
            </div>

            <BaseScrollbar height="230px">
              <div
                v-for="(task, index) in openTaskList.Table"
                :key="index"
                class="row items-center q-pa-sm"
              >
                <div class="col-auto">
                  <BaseIcon
                    name="mdi-progress-download"
                    color="primary-5"
                    size="27px"
                  ></BaseIcon>
                </div>
                <div
                  class="col q-ml-sm clickable"
                  style="cursor: pointer"
                  @click="editTask(task.itemid, 1)"
                >
                  <div
                    class="text-md link-style"
                    @mouseover="isHovered = true"
                    @mouseout="isHovered = false"
                  >
                    {{ task["Task Name"] }}
                  </div>
                  <div class="label text-grey">
                    {{ openDuration(task) }}
                  </div>
                </div>
              </div>
            </BaseScrollbar>
          </div>
        </div>

        <div class="col-4 q-mb-sm">
          <div class="card q-ml-xs q-mr-xs">
            <div class="row items-center">
              <div class="col q-pa-xs">
                <div class="header font-bold">
                  Overdue Tasks ({{ overdueTaskList.Table?.length }})
                </div>
              </div>
              <div class="col-auto">
                <BaseActionButton
                  v-tooltip.top="'refresh'"
                  is-flat
                  no-border
                  icon="eva-refresh"
                  class="q-ml-sm"
                  @click="getOverdueTaskList(formId)"
                />
                <BaseActionButton
                  v-if="showGrid1"
                  :icon="isPin ? 'mdi-pin-off' : 'mdi-pin'"
                  color="secondary"
                  no-border
                  is-flat
                  size="18px"
                  rotate="rotate-90"
                  @click="isPin = !isPin"
                />
              </div>
            </div>
            <BaseScrollbar height="230px">
              <div
                v-for="(task, index) in overdueTaskList.Table"
                :key="index"
                class="row items-center q-pa-sm"
              >
                <div class="col-auto">
                  <BaseIcon
                    name="mdi-progress-clock"
                    color="primary-5"
                    size="27px"
                  ></BaseIcon>
                </div>
                <div
                  class="col q-ml-sm clickable"
                  style="cursor: pointer"
                  @click="editTask(task.itemid, 2)"
                >
                  <div
                    class="text-md link-style"
                    @mouseover="isHovered = true"
                    @mouseout="isHovered = false"
                  >
                    {{ task["Task Name"] }}
                  </div>
                  <div class="label text-grey">
                    {{ overdueDuration(task) }}
                  </div>
                </div>
              </div>
            </BaseScrollbar>
          </div>
        </div>

        <div class="col-4 q-mb-sm">
          <div class="card q-ml-xs">
            <div class="row item-center q-body q-mb-sm">
              <div
                class="col-auto q-mr-xs cursor-pointer header"
                :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                @click="
                  chartDataDrilldownStage.month = '';
                  chartDataDrilldownStage.week = '';
                  getTaskChartCount(selectedForm);
                "
              >
                Month
              </div>
              <div
                v-if="chartDataDrilldownStage.month"
                class="col-auto cursor-pointer header"
                :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                @click="
                  chartDataDrilldownStage.week = '';
                  getTaskChartCount(
                    selectedForm,
                    chartDataDrilldownStage.month
                  );
                "
              >
                / {{ chartDataDrilldownStage.month }}
              </div>
              <div
                v-if="chartDataDrilldownStage.week"
                class="col-auto q-ml-xs header"
                @click="chartDataDrilldownStage.week = ''"
              >
                / {{ chartDataDrilldownStage.week }}
              </div>
            </div>
            <vue-highcharts
              ref="refTaskChart"
              :highcharts="Highcharts"
              :options="chartData"
            ></vue-highcharts>
          </div>
        </div>

        <div class="col-4 q-mb-sm">
          <div class="q-mr-xs border-outline">
            <DonutChart
              title="Task Chart"
              :x-data="pieChartxData"
              :y-data="pieChartyData"
            />
          </div>
        </div>
      </div>
    </BaseScrollbar>
    <AddTask
      v-model="isAddTaskVisible"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="formId"
      :repository-id="repositoryId"
      :task-model="selectedTask"
      :mode="taskMode"
      :task-name="taskName"
      @close="isAddTaskVisible = false"
      @refresh="refreshTasks"
    />
  </div>
</template>

<script>
import { Portal } from "portal-vue";
import { form, report } from "@/api/factory.js";
import VueHighcharts from "vue2-highcharts";
import Drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting.js";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";
import DonutChart from "@/components/common/charts/DonutChart.vue";

Drilldown(Highcharts);
Exporting(Highcharts);

export default {
  name: "TasksOverview",

  components: { Portal, VueHighcharts, AddTask, DonutChart },

  data() {
    return {
      Highcharts: Highcharts,
      series: [
        {
          name: "TASK COUNT",
          color: "#7e61c8",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Tasks Count by Month",
          align: "left",
        },
        // grid: {
        //   row: {
        //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        //     opacity: 0.5,
        //   },
        // },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      chartData: {
        chart: {
          type: "spline",
          height: "225px",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Count",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#bfa0fe"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getTaskChartCount(this.selectedForm, e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getTaskChartCount(
                        this.selectedForm,
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "count",
            data: [],
          },
        ],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      pieChartxData: ["Low", "Medium", "High"],
      pieChartyData: [],
      showGrid1: false,
      showGrid2: false,
      isPin: false,
      formList: [],
      selectedForm: "",
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy1: [
        {
          filters: [
            {
              criteria: "type",
              condition: "IS_EQUALS_TO",
              value: "Task",
              dataType: "",
            },
          ],
          groupCondition: "",
        },
      ],
      filterBy: [],
      itemsPerPage: 50,
      currentPage: 1,
      taskCount: [],
      overdueTaskList: [],
      openTaskList: [],
      formId: 0,
      taskId: 0,
      repositoryId: 0,
      refresh: 0,
      low: 0,
      medium: 0,
      high: 0,
      taskMode: "",
      taskName: "",
      tasks: [],
      tasksData: [],
      selectedTask: {},
      isAddTaskVisible: false,
      addTaskForm: [],
    };
  },

  computed: {
    selectForm() {
      if (this.formList.length) {
        if (this.selectedForm) {
          return this.formList.find((item) => item.value === this.selectedForm)
            .label;
        } else return "Select Form";
      }
      return "Select Form";
    },
  },

  created() {},

  mounted() {
    this.getForms();
  },

  methods: {
    async init() {
      this.getTaskCount(this.formId);
      this.getTaskChartCount(this.formId);
      await this.getOverdueTaskList(this.formId);
      await this.getOpenTaskList(this.formId);
      this.priorityCount();
    },

    refreshTasks() {
      if (this.refresh === 1) {
        this.getOpenTaskList(this.formId);
      } else if (this.refresh === 2) {
        this.getOverdueTaskList(this.formId);
      }
    },

    overdueDuration(task) {
      const date = task.Date instanceof Date ? task.Date : new Date(task.Date);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    openDuration(task) {
      const date = task.Date instanceof Date ? task.Date : new Date(task.Date);

      const now = new Date();
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (date.toDateString() === now.toDateString()) {
        return "today";
      } else if (date.toDateString() === tomorrow.toDateString()) {
        return "tomorrow";
      } else {
        const formattedDate = date.toLocaleDateString("en", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return formattedDate;
      }
    },

    getFormFieldsData(entry) {
      const data = {};
      // console.log(entry);
      for (let field of this.formFields) {
        const complexTypes = [
          "TABLE",
          "MATRIX",
          "FILL_IN_THE_BLANKS",
          "TABS",
          "POPUP",
        ];
        // console.log(field.label);
        if (field.label !== "Reminder" || field.label !== "Notification") {
          // if (field.label !== "Reminder" || field.label !== "Notification") {
          data[field.id] = complexTypes.includes(field.type)
            ? JSON.parse(entry[field.id])
            : entry[field.id];
        }
      }

      return data;
    },

    editTask(id, value) {
      this.taskId = id;
      this.refresh = value;
      this.getFormEntry(this.formId, this.taskId);
    },

    selectedTaskName() {
      this.taskName = this.selectedTask["e_NFnW0JxrLpZxqPeJS7K"];
    },

    priorityCount() {
      this.pieChartyData = [];
      if (this.overdueTaskList.Table && this.overdueTaskList.Table) {
        this.low =
          this.overdueTaskList.Table.filter((task) => task.Priority === 1)
            .length +
          this.openTaskList.Table.filter((task) => task.Priority === 1).length;
        this.medium =
          this.overdueTaskList.Table.filter((task) => task.Priority === 2)
            .length +
          this.openTaskList.Table.filter((task) => task.Priority === 2).length;
        this.high =
          this.overdueTaskList.Table.filter((task) => task.Priority === 3)
            .length +
          this.openTaskList.Table.filter((task) => task.Priority === 3).length;
        this.pieChartyData[0] = this.low;
        this.pieChartyData[1] = this.medium;
        this.pieChartyData[2] = this.high;
      }
    },

    /* api functions */

    async getForms() {
      this.formList = [];

      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.getForms({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy1,
        currentPage: this.currentPage,
        itemsperPage: this.itemsPerPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;

      console.log(payload);

      if (data.length) {
        if (data[0].value.length) {
          data[0].value.forEach((item) => {
            this.formList.push({
              id: item.id,
              label: item.name,
              value: item.id,
              repositoryId: item.repositoryId,
              formJson: item.formJson,
            });
          });
        }
        // console.log("Form List", this.formList);

        if (this.formList.length) {
          this.formId = this.formList[0].value;
          this.repositoryId = this.formList[0].repositoryId;
          this.addTaskForm = JSON.parse(this.formList[0].formJson);
          this.init();
        }
      }
    },

    async getFormEntry() {
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getFormEntry(
        this.formId,
        this.taskId
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload && payload.length > 0) {
        this.taskMode = "EDIT";
        this.selectedTask = payload[0];
        this.selectedTask.id = payload[0].itemid;
        delete this.selectedTask.createdByEmail;
        delete this.selectedTask.modifiedByEmail;
        delete this.selectedTask.isDeleted;
        this.selectedTaskName();

        this.isAddTaskVisible = true;
      } else {
        console.error("No data available in the payload.");
      }
      console.log("form", this.selectedTask);
    },

    async getTaskCount(id) {
      this.selectedForm = id;
      this.$store.commit("showLoadingBarPage");
      let input = { year: new Date().getFullYear() };
      const { error, payload } = await report.getTaskCount(id, input);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload);

      this.taskCount = payload;
    },

    async getOpenTaskList(id) {
      this.selectedForm = id;
      this.$store.commit("showLoadingBarPage");
      let input = { year: new Date().getFullYear(), taskType: "Open" };
      const { error, payload } = await report.getTaskList(id, input);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.openTaskList = payload;
    },

    async getOverdueTaskList(id) {
      this.selectedForm = id;
      this.$store.commit("showLoadingBarPage");
      let input = { year: new Date().getFullYear(), taskType: "Overdue" };
      const { error, payload } = await report.getTaskList(id, input);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.overdueTaskList = payload;
    },

    async getTaskChartCount(id, month = "", weekno = "") {
      console.log(month, weekno);
      this.$store.commit("showLoadingBarPage");
      let input = {
        year: new Date().getFullYear(),
        month: month,
        weekno: weekno,
      };
      const { error, payload } = await report.getTaskChart(id, input);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.Table.length) {
          let yAxis = [];
          if (weekno) {
            payload.Table.forEach((item) => {
              yAxis.push({
                name: item.Days,
                y: Number(item.count),
              });
            });
          } else if (month) {
            payload.Table.forEach((item) => {
              yAxis.push({
                name: "Week " + item.Weekno,
                y: Number(item.count),
              });
            });
          } else {
            payload.Table.forEach((item) => {
              yAxis.push({
                name: item.months,
                y: Number(item.count),
              });
            });
          }
          this.chartData.series[0].data = yAxis;

          let lineCharts = this.$refs.refTaskChart;
          lineCharts.removeSeries();

          lineCharts.addSeries(this.chartData.series[0]);
        }
      }
      if (payload) {
        const monthsData = payload.Table;
        const newData = monthsData.map((month) => month.count);

        this.series[0].data = newData;
      }
    },

    /* .... */
  },
};
</script>

<style lang="scss" scoped>
.link-style {
  cursor: pointer;
  text-decoration: none;
}

.link-style:hover {
  text-decoration: underline;
}
.wrapper {
  position: relative;
  height: calc(100vh - 208px);
}

.card {
  // height: calc(100vh - 235px);
  height: 285px;
  // border: 1px solid #fff;
  padding: 10px;
  border-radius: 10px;
  // background: white;
  background-color: var(--component-bg-color);
  border: 1px solid var(--component-bg-color);

  &.chartBox {
    height: 140px;
  }
}

.border-outline {
  border-radius: 10px;
  border: 1px solid var(--component-bg-color);
}

.header {
  text-anchor: start;
  font-size: 15px;
  font-weight: 700;
  fill: rgb(55, 61, 63);
}

.header-top {
  border-bottom: 1px solid var(--divider-color);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.label {
  color: #a391cf !important;
}

.count {
  color: #8162cc;
  font-weight: 800;
  font-size: 24px;
}
</style>
