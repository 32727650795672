<template>
  <div id="checkbox-field" :class="{ disabled: isDisabled }">
    <div
      :tabindex="0"
      class="option"
      :class="{
        'is-focused': isFocused,
        'no-pointer-events': isDisabled,
        'is-selected': value,
      }"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @click="handleInput"
      @keypress.space.stop="handleInput"
    >
      <BaseIcon
        :name="value ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
        inherit-color
        class="icon"
        :class="[description ? 'q-mr-md' : 'q-mr-sm']"
      />

      <div>
        <div class="label">{{ label }}</div>
        <div v-if="description" class="description">{{ description }}</div>
      </div>
    </div>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "CheckboxField",

  components: { FormFieldError },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
    };
  },

  methods: {
    handleInput() {
      if (this.isDisabled) {
        return;
      }

      this.$emit("input", !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#checkbox-field {
  user-select: none;

  .option {
    display: flex;
    align-items: center;
    outline: none;

    .label {
      color: var(--icon-color);
    }

    .icon {
      color: var(--icon-color-inverted);
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-top: 4px;
    }

    &:hover {
      cursor: pointer;

      .icon,
      .label {
        color: var(--secondary);
      }
    }

    &.is-focused,
    &.is-selected {
      .icon {
        color: var(--secondary);
      }
    }

    &.is-selected {
      .label {
        font-weight: 500;
        color: var(--body-text-color);
      }
    }
  }
}
</style>
